import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./BlueSliderBlock.css"

// Function to calculate dynamic heights based on line count
const calculateHeights = lineCount => {
  // Cap the line count at 6 lines maximum
  const cappedLineCount = Math.min(lineCount, 6)

  // Base values - reduced for more compact cards
  const baseCardHeight = 280 // px - reduced minimum height for card

  // Additional height per line
  const heightPerLine = 22 // px per line

  // Calculate heights
  const cardHeight = baseCardHeight + cappedLineCount * heightPerLine

  return {
    cardHeight: `${cardHeight}px`,
  }
}

// Function to estimate line count based on text
const estimateLineCount = (text, charsPerLine = 60) => {
  if (!text) return 2 // Default if no text
  // Count actual line breaks
  const newlineCount = (text.match(/\n/g) || []).length
  // Estimate based on character count
  const charBasedLines = Math.ceil(text.length / charsPerLine)
  // Use whichever is larger, but cap at 6 lines max
  return Math.min(6, Math.max(2, Math.max(newlineCount, charBasedLines)))
}

// Include SliderComponent inline
function SliderComponent({ number, title, description, lineCount }) {
  // Calculate heights based on line count
  const { cardHeight } = calculateHeights(lineCount)

  return (
    <div className="card" style={{ height: cardHeight }}>
      {/* Card Content */}
      <div className="flex flex-col items-start h-full">
        <div
          className="text-8xl md:text-9xl font-bold select-none rounded-2xl text-white flex items-center justify-center mb-4"
          style={{
            opacity: 0.1,
            fill: "var(--functional-bg-light-bgf-0-f-4-ff, #F0F4FF)",
          }}
        >
          {String(number).padStart(2, "0")}
        </div>
        <h3 className="text-xl lg:text-3xl font-bold text-white mb-3">
          {title}
        </h3>
        <p className="text-left font-normal text-lg text-white leading-relaxed overflow-hidden">
          {description}
        </p>
      </div>
    </div>
  )
}

export default function BlueSliderBlock({ BlueSliderCard }) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef(null)

  console.log("bluesliderprop", BlueSliderCard)

  // Get data from props
  const blueSliderData =
    BlueSliderCard?.blueSliderCard?.[0]?.BluesliderCPT || {}
  const title = blueSliderData?.title
  const subtitle = blueSliderData?.subtitle

  // Get slider items from props or use fallback empty array
  const items =
    blueSliderData?.blueSliderValues?.map(item => ({
      number: item.number,
      title: item.title,
      description: item.description,
      lineCount: estimateLineCount(item.description),
    })) || []

  console.log("Processed items:", items)

  // Find the maximum line count
  const maxLineCount =
    items.length > 0 ? Math.max(...items.map(item => item.lineCount)) : 4 // Default if no items

  // Calculate uniform heights for all cards based on the max line count
  const { cardHeight } = calculateHeights(maxLineCount)

  // Add CSS for the cards to ensure proper styling
  useEffect(() => {
    const styleTag = document.createElement("style")
    styleTag.innerHTML = `
      .card {
        height: ${cardHeight} !important;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
      }
      
      /* Ensure slides have equal height */
      .slick-track {
        display: flex !important;
      }
      
      .slick-slide {
        height: inherit !important;
      }
      
      .slick-slide > div {
        height: 100%;
      }
    `
    document.head.appendChild(styleTag)

    return () => {
      document.head.removeChild(styleTag)
    }
  }, [cardHeight])

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
  }

  return (
    <div className="relative w-full bg-blue-500 overflow-hidden">
      <div className="absolute inset-0 bg-cover bg-center"></div>
      <div className="relative max-w-screen-2xl mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1
            className="text-2xl md:text-5xl font-bold text-white px-6 md:px-10 py-1 md:py-2 inline-block"
            style={{
              borderRadius: "40px",
              border: "6px solid rgba(255, 249, 249, 0.08)",
              background: "rgba(255, 255, 255, 0.16)",
            }}
          >
            {title}
          </h1>
        </div>

        <p className="text-center text-lg font-medium mb-12 max-w-3xl mx-auto text-white">
          {subtitle}
        </p>

        <div className="relative md:px-8 md:py-12">
          <Slider ref={sliderRef} {...settings} className="gap-slider">
            {items.map((item, index) => (
              <SliderComponent
                key={index}
                number={item.number}
                title={item.title}
                description={item.description}
                lineCount={maxLineCount} // Use max line count for uniform height
              />
            ))}
          </Slider>

          <div className="flex justify-center items-center gap-4 mt-8">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              className="w-12 h-12 rounded-full border border-white/30 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
            >
              <i
                className="icon-slider-left-arrow text-white flex justify-center py-3 mx-0 active:text-blue-500"
                style={{ fontSize: "20px" }}
              />
            </button>

            <span className="text-white text-lg font-bold">
              {currentSlide + 1} /{" "}
              <span className="text-white text-lg font-bold opacity-50 ">
                {items.length}
              </span>
            </span>
            <button
              type="button"
              onClick={() => sliderRef.current?.slickNext()}
              className="w-12 h-12 rounded-full border border-white/30 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
            >
              <i
                className="icon-slider-right-arrow text-white flex justify-center py-3 mx-0 active:text-blue-500"
                style={{ fontSize: "20px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
