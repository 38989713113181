import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../Layout"
import SEO from "./../components/SEO"
import { ComponentsMap } from "../util/components"
import IntersectionObserver from "../components/IntersectionObserver"

const PageTemplateV2 = ({ data, location }) => {
  // const [components, setComponents] = useState([])
  const color = data?.wpCaseStudy?.CaseStudyCPTBlock?.color

  const content = data?.wpCaseStudy?.blocks?.map((data, index) => {
    const { name } = data
    const blockToImport = ComponentsMap.get(name)
    const Component = require(`../components/blocks/${blockToImport}`).default

    return (
      <div key={index}>
        <IntersectionObserver>
          <Component {...data} index={index} theme={color} />
        </IntersectionObserver>
      </div>
    )
  })

  // useEffect(() => {
  //   data.wpCaseStudy.blocks.map((i, index) => importBlock(i, index))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data])

  // const importBlock = async (data, index) => {
  //   const { name } = data
  //   const blockToImport = ComponentsMap.get(name)
  //   await import(`../components/blocks/${blockToImport}`)
  //     .then(Component =>
  //       setComponents(loadedComponents =>
  //         loadedComponents.concat(
  //           <div index={index} key={Math.random()}>
  //           <IntersectionObserver>
  //             <Component.default {...data} index={index} theme={color} />
  //           </IntersectionObserver>
  //           </div>
  //         )
  //       )
  //     )
  //     .catch(error => {
  //       console.error(`"${error}" not yet supported`)
  //     })
  // }

  // const content = components
  //   .sort((a, b) => a.props.index - b.props.index)
  //   .map(Component => Component)

  return (
    <Layout location={location}>
      <SEO
        title={data?.wpCaseStudy?.seo?.title}
        desc={data?.wpCaseStudy?.seo?.metaDesc}
        banner={data?.wpCaseStudy?.featuredImage?.node?.mediaItemUrl}
        ogurl={data?.wpCaseStudy?.seo?.opengraphUrl}
        ogtitle={data?.wpCaseStudy?.seo?.opengraphTitle}
        ogdesc={data?.wpCaseStudy?.seo?.opengraphDescription}
        ogbanner={data?.wpCaseStudy?.seo?.opengraphImage?.mediaItemUrl}
        tweettitle={data?.wpCaseStudy?.seo?.twitterTitle}
        tweetdesc={data?.wpCaseStudy?.seo?.twitterDescription}
        tweetbanner={data?.wpCaseStudy?.seo?.twitterImage?.mediaItemUrl}
        metaRobotsNoindex={data?.wpPage?.seo?.metaRobotsNoindex}
        canonical={`https://webmobtech.com${location.pathname}`}
        schemas={data?.wpCaseStudy?.JSONLdBlock?.jsonLd}
      />
      {content}
    </Layout>
  )
}

export default PageTemplateV2

export const query = graphql`
  query($databaseId: Int!) {
    wpCaseStudy(databaseId: { eq: $databaseId }) {
      id
      JSONLdBlock {
        jsonLd {
          jsonldSchema
        }
      }
      seo {
        title
        metaDesc
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphUrl
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
        metaRobotsNoindex
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      CaseStudyCPTBlock {
        color
      }
      blocks {
        name
        __typename

        ... on WpCoreShortcodeBlock {
          attributes {
            text
          }
        }

        ... on WpAcfSpacerBlock {
          SpacerBlock {
            spacer {
              desktop
              mobile
            }
          }
        }

        ... on WpAcfAppstoreBlock {
          AppStoreBlock {
            androidPlayStoreLink
            iosAppStoreLink
          }
        }

        ... on WpAcfMasonryPhotoGridBlock {
          MasonryPhotoGrid {
            gridSize
            title
            subtitle
            images {
              mediaItemUrl
              srcSet
              altText
              mediaDetails {
                width
                height
              }
            }
          }
        }

        ... on WpAcfWorkPortfolioSliderBlock {
          WorkPortfolioSlider {
            title
            description
            portfolio {
              ... on WpWork {
                title
                projectFeatureTag {
                  nodes {
                    name
                  }
                }
                projectCountryTag {
                  nodes {
                    name
                  }
                }
                WorkCPT {
                  tagline
                  textAlign
                  coverImage {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  android {
                    url
                  }
                  ios {
                    url
                  }
                  website {
                    url
                  }
                  caseStudy {
                    url
                  }
                }
              }
            }
          }
        }

        ... on WpAcfTechStackCardsBlock {
          TechStackCardBlock {
            title
            gridSize
            techCard {
              title
              techStack {
                ... on WpTechStack {
                  title
                  excerpt
                }
              }
            }
          }
        }

        ... on WpAcfTimelineSliderBlock {
          TimeLineSlider {
            timeline {
              year
              title
              description
              images {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }

        ... on WpAcfFaqBlock {
          FAQ {
            title
            type
            questions {
              question
              answer
            }
            firstid
          }
        }
        ... on WpAcfCtaBlock {
          CTABlock {
            action1 {
              title
              url
            }
            action2 {
              title
              url
            }
            image {
              mediaItemUrl
              srcSet
              altText
              mediaDetails {
                width
                height
              }
            }
            type
            title
            subtitle
          }
        }
        ... on WpAcfHiringModelBlock {
          HiringModelBlock {
            hiringCard {
              cardTitle
              popular
              subtitle
              description
            }
            ctaButton {
              target
              title
              url
            }
          }
        }

        ... on WpAcfMyTeamsBlock {
          MyTeams {
            title
            member {
              ... on WpTeam {
                MyTeamsBlock {
                  firstname
                  lastname
                  bio
                  photo {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }

        ... on WpAcfWorkCardBlock {
          WorkCard {
            projects {
              ... on WpWork {
                title
                projectFeatureTag {
                  nodes {
                    name
                  }
                }
                WorkCPT {
                  tagline
                  textAlign
                  coverImage {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  android {
                    url
                  }
                  ios {
                    url
                  }
                  website {
                    url
                  }
                  caseStudy {
                    url
                  }
                }
              }
            }
          }
        }

        ... on WpAcfCenterMediaCenterContentBlock {
          CenterMediaContentBlock {
            blockWidth
            animationColor {
              bgColor
              textColor
            }
            component {
              image {
                mediaDetails {
                  width
                  height
                }
                mediaItemUrl
                srcSet
                altText
              }
              content {
                description
                fieldGroupName
                tagline
                title
                tags {
                  name
                }
                listpoint {
                  list
                }
              }
            }
            mediaType
            ctaLink {
              target
              title
              url
            }
          }
        }

        ... on WpAcfRightMediaLeftContentBlock {
          RightMediaLeftContentBlock {
            blockWidth
            animationColor {
              bgColor
              textColor
            }
            component {
              video
              image {
                mediaDetails {
                  height
                  width
                }
                mediaItemUrl
                srcSet
                altText
              }
              content {
                align
                description
                fieldGroupName
                tagline
                title
                tags {
                  name
                }
                techImages {
                  iconsUrl {
                    url
                  }
                  techIcons {
                    mediaDetails {
                      height
                      width
                    }
                    mediaItemUrl
                    srcSet
                    altText
                  }
                  techTitle
                }
                logo {
                  mediaItemUrl
                  srcSet
                  altText
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
            mediaType
            ctaLink {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
        }

        ... on WpAcfCardGridBgImgBlock {
          CardGridBGImageBlock {
            position
            gridSize
            gridCards {
              backgroundImage {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  width
                  height
                }
              }
              description
              onClickLink {
                ... on WpPage {
                  uri
                }
              }
              title
            }
          }
        }
        ... on WpAcfThreeGridFeaturesBlock {
          ThreeGridFeaturesBlock {
            type
            size
            feature {
              fontello
              cta {
                title
                url
              }
              description
              icon {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  height
                  width
                }
              }
              title
              siconlink {
                target
                url
              }
              sicon {
                altText
                mediaItemUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
              bulletpoints {
                subpoints
              }
            }
          }
        }
        ... on WpAcfTitleSubtitleBlock {
          CommonTitleSubtitleBlock {
            type
            title
            subtitle
            timeDuration
            members
            country
            backgroundCover {
              mediaItemUrl
              srcSet
              altText
            }
            ctaButton {
              title
              target
              url
            }
            ctaButton2 {
              title
              target
              url
            }
            contactForm
          }
        }

        ... on WpAcfTitleSubtitleSliderBlock {
          TitleSubtitleSliderBlock {
            sliderGroup {
              title
              subtitle
              cta {
                title
                url
              }
              backgroundCover {
                srcSet
                altText
                mediaItemUrl
              }
            }
          }
        }
        ... on WpAcfLeftMediaRightContentBlock {
          LeftMediaRightContentBlock {
            mainTitle
            mainDescription
            blockWidth
            animationColor {
              bgColor
              textColor
            }
            component {
              image {
                mediaItemUrl
                srcSet
                altText
                mediaDetails {
                  height
                  width
                }
              }
              content {
                align
                description
                fieldGroupName
                tagline
                title
                tags {
                  name
                }
                techImages {
                  iconUrl {
                    url
                  }
                  techIcons {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      height
                      width
                    }
                  }
                  techTitle
                }
                logo {
                  mediaItemUrl
                  srcSet
                  altText
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
            mediaType
            ctaLink {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
        }

        ... on WpAcfVideoLinksBlock {
          VideoLinksBlock {
            videoLinks {
              link
            }
          }
        }

        ... on WpAcfVideoTestimonialsBlock {
          dynamicContent
          originalContent
          VideoTestimonials {
            select
            testimonials {
              ... on WpVideoTestimonial {
                id
                title
                VideoTestimonialsCPT {
                  subtitle
                  link
                  poster {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpAcfImageTestimonialsBlock {
          dynamicContent
          originalContent
          ImageTestimonials {
            select
            testimonials {
              ... on WpImageTestimonial {
                id
                title
                ImagesTestimonialsCPT {
                  poster {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpAcfAllLogoBlock {
          Logos {
            title
            type
          }
        }
        ... on WpAcfTechTabStackBlock {
          originalContent
          TechTabStacks {
            division {
              divId
              title
              imagesArray {
                ... on WpTechTabStack {
                  id
                  TechStackTabCPT {
                    cta {
                      target
                      title
                      url
                    }
                    image {
                      mediaItemUrl
                      title
                      altText
                      mediaDetails {
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpAcfTestimonialsBlock {
          testimonials {
            type
          }
        }
        ... on WpAcfCommonStatisticsBlock {
          originalContent
          statistics {
            subtitle
            title
            type
            statisticsCards {
              ... on WpStatistic {
                id
                statisticsCPT {
                  statisticsValues {
                    number
                    description
                  }
                }
              }
            }
          }
        }

        ... on WpAcfTechnologyToolsBlock {
          TechnologyToolsBlock {
            gridSize
            feature {
              ... on WpTechnologyTool {
                TechnologyToolsCPT {
                  cta {
                    title
                    url
                  }
                  description
                  icon {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  title
                  siconlink {
                    target
                    url
                  }
                  sicon {
                    altText
                    mediaItemUrl
                    srcSet
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }

        ... on WpAcfColorComponentBlock {
          innerBlocks {
            name
            ... on WpAcfCenterMediaCenterContentBlock {
              CenterMediaContentBlock {
                blockWidth
                animationColor {
                  bgColor
                  textColor
                }
                component {
                  image {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  content {
                    description
                    fieldGroupName
                    tagline
                    title
                    tags {
                      name
                    }
                  }
                }
                mediaType
                ctaLink {
                  target
                  title
                  url
                }
              }
            }
            ... on WpAcfRightMediaLeftContentBlock {
              RightMediaLeftContentBlock {
                blockWidth
                animationColor {
                  bgColor
                  textColor
                }
                component {
                  video
                  image {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  content {
                    align
                    description
                    fieldGroupName
                    tagline
                    title
                    tags {
                      name
                    }
                    techImages {
                      iconsUrl {
                        url
                      }
                      techIcons {
                        mediaItemUrl
                        srcSet
                        altText
                        mediaDetails {
                          width
                          height
                        }
                      }
                      techTitle
                    }
                    logo {
                      mediaItemUrl
                      srcSet
                      altText
                      mediaDetails {
                        height
                        width
                      }
                    }
                  }
                }
                mediaType
                ctaLink {
                  target
                  title
                  url
                }
                ctaLink2 {
                  target
                  title
                  url
                }
              }
            }
            ... on WpAcfLeftMediaRightContentBlock {
              LeftMediaRightContentBlock {
                blockWidth
                animationColor {
                  bgColor
                  textColor
                }
                component {
                  image {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      width
                      height
                    }
                  }
                  content {
                    align
                    description
                    fieldGroupName
                    tagline
                    title
                    tags {
                      name
                    }
                    techImages {
                      iconUrl {
                        url
                      }
                      techIcons {
                        mediaItemUrl
                        srcSet
                        altText
                        mediaDetails {
                          width
                          height
                        }
                      }
                      techTitle
                    }
                    logo {
                      mediaItemUrl
                      srcSet
                      altText
                      mediaDetails {
                        height
                        width
                      }
                    }
                  }
                }
                mediaType
                ctaLink {
                  target
                  title
                  url
                }
                ctaLink2 {
                  target
                  title
                  url
                }
              }
            }
            ... on WpAcfVerticalTabBlock {
              dynamicContent
              originalContent
              VerticalTab {
                title
                tabs {
                  divId
                  tabTitle
                  subTitle
                  caseStudyLink {
                    link {
                      target
                      title
                      url
                    }
                  }
                  pageDetailLink {
                    target
                    title
                    url
                  }
                  image {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
              }
            }

            ... on WpAcfOurWorkBlock {
              OurWork {
                title
                cards {
                  title
                  subtitle
                  tags {
                    tag
                  }
                  image {
                    mediaItemUrl
                    srcSet
                    mediaDetails {
                      height
                      width
                    }
                    altText
                  }
                  link {
                    target
                    title
                    url
                  }
                }
              }
            }

            ... on WpAcfSetionSliderBlock {
              SectionSliderBlock {
                cards {
                  title
                  subtitle
                  description
                  ctaLink {
                    target
                    title
                    url
                  }
                  ctaLink2 {
                    target
                    title
                    url
                  }
                  image {
                    mediaItemUrl
                    srcSet
                    altText
                    mediaDetails {
                      height
                      width
                    }
                  }
                  pageDetailLink {
                    target
                    title
                    url
                  }
                }
                title
                subTitle
              }
            }
            ... on WpAcfAwardsBlock {
              dynamicContent
              originalContent
              Awards {
                fieldGroupName
              }
            }
            ... on WpAcfVideoTestimonialsBlock {
              dynamicContent
              originalContent
              VideoTestimonials {
                select
                testimonials {
                  ... on WpVideoTestimonial {
                    id
                    title
                    VideoTestimonialsCPT {
                      subtitle
                      link
                      poster {
                        mediaItemUrl
                        srcSet
                        altText
                        mediaDetails {
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on WpAcfAllLogoBlock {
              Logos {
                title
                type
              }
            }

            ... on WpAcfWhiteSliderBlock {
              originalContent
              WhiteSliderCard {
                whiteSliderCard {
                  ... on WpWhiteSlider {
                    WhiteSliderCPT {
                      whiteSliderValues {
                        title
                        icon
                        description
                        number
                      }
                    }
                  }
                }
              }
            }
            ... on WpAcfBlueSliderBlock {
              originalContent
              BlueSliderCard {
                blueSliderCard {
                  ... on WpBlueSlider {
                    BluesliderCPT {
                      title
                      subtitle
                      blueSliderValues {
                        title
                        description
                        number
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
